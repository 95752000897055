<template>
  <Collections
    is-community-collections
    hide-column-members
    is-shared-page
    :is-workspace-edit-page="isWorkspaceEditPage"
    :is-workspace-page="isWorkspacePage"
    :expanded-collections="expandedCollections"
    :filtered-list-of-collections="filteredListOfCollections">
    <template
      #expandedIcon="{ group }">
      <p
        class="mb-0 d-flex align-center"
        @click="changeCollectionsToShow({
          group,
        })">
        <a class="text-uppercase">
          {{ setExpandTitle({group}) }}
        </a>
      </p>
    </template>
  </Collections>
</template>
<script>
import Collections from '@/components/Collections';
import {
  mapGetters, mapMutations,
} from 'vuex';
import {
  ROUTE_COMMUNITY_COLLECTIONS, ROUTE_WORKSPACE_PAGE,
} from '@/constants';
export default {
  name: 'CommunityCollections',
  components: {
    Collections,
  },
  props: {
    isWorkspacePage: {
      type: Boolean,
      default: false,
    },
    isWorkspaceEditPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandedCollections: {
      },
    };
  },
  computed: {
    ...mapGetters({
      getCollectionsGroups: 'Collections/getAppropriateCollectionsGroupsByCriteria',
      getCommunityCollectionGroup: 'Collections/getCommunityCollectionGroup',
    }),
    routeName() {
      return [ROUTE_COMMUNITY_COLLECTIONS, ROUTE_WORKSPACE_PAGE];
    },
    filteredListOfCollections() {
      const { getCollectionsGroups, routeName } = this;
      if (this.isWorkspacePage) {
        return [this.getCommunityCollectionGroup];
      }
      return getCollectionsGroups({
        routeName,
      });
    },
  },
  mounted() {
    this.updateActiveElement({
      kind: 'default',
    });
  },
  methods: {
    ...mapMutations(['updateActiveElement']),
    setExpandTitle({ group }) {
      return this.expandedCollections[group] ?
        'collapsed view' : 'see all';
    },
    changeCollectionsToShow({ group }) {
      this.expandedCollections = {
        ...this.expandedCollections,
        [group]: !this.expandedCollections[group],
      };
    },
  },
};
</script>
